import React, { useEffect } from 'react';
import {rect,text} from './index.module.scss'
import gsap from 'gsap';

interface ProgressBarProps {
    progress: number;
    totalSupply:number;
    remaining:number;
}

const ProgressBar = ({progress,totalSupply, remaining}: ProgressBarProps)=>{

    useEffect(()=>{
        gsap.to(`.${rect}`, {scaleX: progress, duration:0})
    });

    return(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 718 90.81">
    <g id="Layer_1" data-name="Layer 1">
    <g id="_3" data-name="3">
    <path d="M184.77,471.1H168.25a10.75,10.75,0,0,0-10.75,10.75v60.3a10.75,10.75,0,0,0,10.75,10.75h16.52" transform="translate(-153 -466.6)" fill="none" stroke="#fff" strokeMiterlimit="10" stroke-width="9"/>
    <path d="M839.23,471.1h16.52a10.75,10.75,0,0,1,10.75,10.75v60.3a10.75,10.75,0,0,1-10.75,10.75H839.23" transform="translate(-153 -466.6)" fill="none" stroke="#fff" strokeMiterlimit="10" stroke-width="9"/>
    <rect className={rect} x="18.13" y="18.13" width="683.2" height="54.54" rx="12.19" fill="rgb(243,255,0)"  />
    <text className={text} dominant-baseline="middle" text-anchor="middle" x='50%' y='50%' font-size="28" stroke="#f3ff00"  strokeMiterlimit="10" font-family="Druk-WideSuper, Druk WideSuper">{progress >=1 ? "100% MINTED" : `${remaining}/${totalSupply} Frycooks Minted`}</text>
    </g>
    </g>
    </svg>); 
    }

export default ProgressBar;