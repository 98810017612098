import React from 'react';
import {ingCardContainer,ingCardImage,ingCardImageContainer,redemedImg,ingCardText,redeemedText,checkWrapper,checkContainer,check} from './index.module.scss';
import Checkmark from '../svg/checkmark';
const IngCard = ({key, src, redeemed, selected, text, pos, onClick, disabled}: {key: number, disabled:boolean, src:string, redeemed: boolean, selected: boolean, text:string, pos:string, onClick: ()=>void}) => {


    return (

        <button onClick={onClick} disabled={disabled} key={key} className={ingCardContainer}>
            <div className={ingCardImageContainer}>
            <img src={src} className={`${ingCardImage} ${redeemed || selected ? `${redemedImg}` : ""}`  }/>
            {redeemed && <p className={redeemedText}>redeemed</p>}
            {selected && 
                <div className={""}>
                    <div className={checkContainer}>
                        <Checkmark className={check}/>
                    </div>
                </div> 
            }
            </div>
            <p className={ingCardText}>{text}</p>
            <p className={ingCardText}>{pos}</p>

            
        </button>
    );

}


export default IngCard;