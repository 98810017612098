import React, { SVGAttributes } from 'react'


const Frown = ({className}: SVGAttributes<SVGElement>)=> {

    return(
<svg viewBox="0 0 49 91" fill="none" xmlns="http://www.w3.org/2000/svg">
<path className={className} d="M2.832 41.4C2.192 41.4 1.648 41.208 1.2 40.824C0.816 40.376 0.624001 39.832 0.624001 39.192V32.472C0.624001 31.832 0.816 31.32 1.2 30.936C1.648 30.488 2.192 30.264 2.832 30.264H9.648C10.352 30.264 10.896 30.488 11.28 30.936C11.728 31.32 11.952 31.832 11.952 32.472V39.192C11.952 39.832 11.728 40.376 11.28 40.824C10.896 41.208 10.352 41.4 9.648 41.4H2.832ZM2.832 75C2.192 75 1.648 74.808 1.2 74.424C0.816 73.976 0.624001 73.432 0.624001 72.792V66.072C0.624001 65.432 0.816 64.92 1.2 64.536C1.648 64.088 2.192 63.864 2.832 63.864H9.648C10.352 63.864 10.896 64.088 11.28 64.536C11.728 64.92 11.952 65.432 11.952 66.072V72.792C11.952 73.432 11.728 73.976 11.28 74.424C10.896 74.808 10.352 75 9.648 75H2.832ZM46.4985 90.264C42.3385 90.264 38.8185 89.368 35.9385 87.576C33.0585 85.848 30.7545 83.16 29.0265 79.512C27.2985 75.864 26.0185 71.224 25.1865 65.592C24.4185 59.896 24.0345 53.144 24.0345 45.336C24.0345 37.528 24.4185 30.808 25.1865 25.176C26.0185 19.48 27.2985 14.808 29.0265 11.16C30.7545 7.512 33.0585 4.824 35.9385 3.096C38.8185 1.368 42.3385 0.503998 46.4985 0.503998C47.0745 0.503998 47.5865 0.727997 48.0345 1.17599C48.4825 1.624 48.7065 2.136 48.7065 2.712V6.07199C48.7065 6.64799 48.4825 7.16 48.0345 7.608C47.6505 8.056 47.2345 8.27999 46.7865 8.27999C44.0985 8.344 41.8265 9.048 39.9705 10.392C38.1785 11.672 36.7065 13.752 35.5545 16.632C34.4665 19.512 33.6665 23.32 33.1545 28.056C32.7065 32.728 32.4825 38.488 32.4825 45.336C32.4825 52.184 32.7065 57.976 33.1545 62.712C33.6665 67.384 34.4665 71.192 35.5545 74.136C36.7065 77.016 38.1785 79.096 39.9705 80.376C41.8265 81.72 44.0985 82.424 46.7865 82.488C47.2345 82.488 47.6505 82.712 48.0345 83.16C48.4825 83.608 48.7065 84.12 48.7065 84.696V88.056C48.7065 88.632 48.4825 89.144 48.0345 89.592C47.5865 90.04 47.0745 90.264 46.4985 90.264Z" fill="#FAFF00"/>
</svg>)
};

export default Frown





