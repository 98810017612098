import React, { useEffect } from 'react';
import {checkmark, path, fill,check, checkComplete, pathComplete, fillComplete, checkmarkComplete } from './index.module.scss';

interface SpinnerProps {
    adoptState: string | null
    resetSpinner: ()=>void
}

const ADOPT_STATE = {
    ADOPTING: "adopting",
    ADOPTED: "adopted",
  }

const Spinner = ({adoptState, resetSpinner}:SpinnerProps)=>{
    useEffect(()=>{
        if(adoptState === ADOPT_STATE.ADOPTED){
            setTimeout(() => {
                resetSpinner();
            }, 2000);
        }
    },[adoptState])

    const isAdopted = () => {
        return adoptState===ADOPT_STATE.ADOPTED
    };

    return(
    <>
    { !!adoptState &&
    <div className={`${checkmark} ${isAdopted() ? checkmarkComplete :""}`}>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0, 0, 100, 100" id="checkmark">
        <g transform="">
	        <circle className={`${path} ${isAdopted() ? pathComplete  : ""}`} fill="none"  strokeWidth="4" strokeMiterlimit="10" cx="50" cy="50" r="44"/>
            <circle className={`${fill} ${isAdopted() ? fillComplete :""}`}  fill="none" strokeWidth="4" strokeMiterlimit="10" cx="50" cy="50" r="44"/>
	        <polyline className={`${check} ${isAdopted()? checkComplete :""}`}  fill="none" strokeWidth="8" strokeLinecap="round" strokeMiterlimit="10" 
                points="70,35 45,65 30,52  "/>
        </g>
    </svg>
</div>
}
</>
    );
};



export default Spinner;